import { Image } from '@chakra-ui/image'
import { Box, Container, Flex, Heading } from '@chakra-ui/layout'

import logo from '../../assets/dms-logo.svg'
import sponsorLogos from '../../assets/dms-sponsor-logos.png'

export function Header() {
  return (
    <Box as="header" py={5} bg="#fff" position="sticky" top={0}>
      <Container maxW="100%" px="60px">
        <Flex alignItems="center" justifyContent="space-between">
          <Flex alignItems="center">
            <Image
              src={logo}
              className="App-logo"
              width="40px"
              alt="DMS logo"
            />
            <Heading ml={2} fontSize="xl">
              DMS
            </Heading>
          </Flex>

          <Box maxW="300px">
            <Image
              src={sponsorLogos}
              className="App-logo"
              alt="Sponsor logos"
            />
          </Box>
        </Flex>
      </Container>
    </Box>
  )
}
