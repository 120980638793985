import { Header, Hero } from './components/organisms'

function App() {
  return (
    <div className="App">
      {/* <Announcement /> */}
      <Header />
      <Hero />
    </div>
  )
}

export default App
