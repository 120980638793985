import { Box, Flex, Heading } from '@chakra-ui/layout'
import { SimpleGrid, Text, Image, Button } from '@chakra-ui/react'

import collage from '../../assets/login-image.png'
import kadunaLogo from '../../assets/kaduna-logo.png'
import anambraLogo from '../../assets/anambra-logo.png'

export function Hero() {
  return (
    <Box as="section" bg="#002E59" color="white">
      <SimpleGrid columns={2} alignItems="center">
        <Box mx="60px" py="100px">
          <Heading as="h1" textTransform="uppercase" mb={4}>
            Data Management System
          </Heading>
          <Text mb={2}>
            Data input, analysis, output and usage
            is needed to build civil society effectiveness.
          </Text>
          <Text mb={2}>
            This project generates and uses subjective and objective data to increase
            accountability, responsiveness and effectiveness of Civil Society Organisations, government and the private sector.
          </Text>

          <Text
            mt={6}
            fontSize="sm"
            fontWeight="600"
            textTransform="uppercase"
            letterSpacing="2px"
          >
            Available In
          </Text>
          <Flex mt={3} gridGap={6}>
            <a href="https://dms.kdbs.ng">
              <Image src={kadunaLogo} width="80px" />
            </a>
            <a href="https://anambrasbs.com">
              <Image src={anambraLogo} width="80px" />
            </a>

            <Flex
              bg="#fff"
              alignItems="center"
              justifyContent="center"
              borderRadius={99999}
              width="80px"
            >
              <a href="mailto:hi@eyeti.xyz">
                <Button size="sm" variant="ghost" colorScheme="red">
                  Mail Us
                </Button>
              </a>
            </Flex>
          </Flex>
        </Box>
        <Flex justifyContent="center" bgImage={collage} height="100%" />
      </SimpleGrid>
    </Box>
  )
}
